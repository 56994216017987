// routes
import AppRouting from "./util/routing";

// styles
import "./App.css";


function App() {
  return <AppRouting classname="bg-[#FEF8F0]" />;
}

export default App;
