import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Navbar from "../components/general/nav.js";
import Footer from "../components/general/footer.js";
import Samples from "../pages/samples.js";
import Home from "../pages/home.js";
import Services from "../pages/services.js";
import ContactUs from "../pages/contactUs.js";
import AboutUs from "../pages/aboutUs.js";
import IndividualProcess from "../components/services/individualProcess.js";
import NotFound from "../pages/notFound.js";
import Clients from "../pages/clients.js";
import ToTop from "./scrollToTop";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import GetAQuote from "../components/popUps/GetAQuote.js";

const MainLayout = ({ children, isOpen, setIsOpen }) => {
  return (
    <>
      <Navbar />
      <div className="pt-20">{children}</div>
      <Footer />
    </>
  );
};
const routing = () => {
  return (
    <Router>
      <ToastContainer />
      <ToTop />
      <Routes>
        <Route
          exact
          path="/"
          element={
            <MainLayout>
              <Home />
            </MainLayout>
          }
        />
        <Route
          exact
          path="/services"
          element={
            <MainLayout>
              <Services />
            </MainLayout>
          }
        />
        <Route
          exact
          path="/quote"
          element={
            <MainLayout>
              <GetAQuote />
            </MainLayout>
          }
        />
        <Route
          exact
          path="services/samples"
          element={
            <MainLayout>
              <Samples />
            </MainLayout>
          }
        />
        <Route
          exact
          path="/contactUs"
          element={
            <MainLayout>
              <ContactUs />
            </MainLayout>
          }
        />
        <Route
          exact
          path="/aboutUs"
          element={
            <MainLayout>
              <AboutUs />
            </MainLayout>
          }
        />

        <Route
          exact
          path="services/:process"
          element={
            <MainLayout>
              <IndividualProcess />
            </MainLayout>
          }
        />
        <Route
          exact
          path="/clients"
          element={
            <MainLayout>
              <Clients />
            </MainLayout>
          }
        />

        {/* default routes for invalid links */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default routing;
