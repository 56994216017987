import React, { useState, useEffect } from "react";
import { BiPhone } from "react-icons/bi";
import { SlLocationPin } from "react-icons/sl";
import { Link } from "react-router-dom";

const Footer = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const today = new Date();
  const year = today.getFullYear();

  const services = [
    { name: "Printing", path: "/services/printing" },
    { name: "Post Press", path: "/services/postPress" },
    { name: "Pre Press", path: "/services/prePress" },
    { name: "Print On Demand", path: "/services/printOnDemand" },
  ];

  const aboutUs = [
    { name: "About Us", path: "/aboutUs" },
    { name: "Services", path: "/services" },
    { name: "Contact us", path: "/contactUs" },
  ];

  const otherLinks = [
    { name: "Home", path: "/" },
    { name: "Clients", path: "/clients" },
    { name: "Samples", path: "/services/samples" },
  ];

  return (
    <footer className="bg-[#FEF5E5] py-12 font-poppins relative z-30 w-full border-t-2 border-t-[#FFCD12]">
      <div className="px-5 md:px-12">
        <div className="grid grid-cols-1 lg:grid-cols-[20%_75%] border-b-2 pb-6 gap-8 md:gap-12">
          <div>
            <img
              src={require("../../assets/images/logo.png")}
              alt="logo"
              className="mb-4 h-20 w-18"
            />
            <p className="text-sm text-[#0E0E0D]/70 leading-relaxed">
              Established in 1988, Sewa Printing Press is promoted by highly
              committed, innovative, and well-experienced individuals and
              managed by a team of young and energetic professionals.
            </p>
          </div>

          <div className=" grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-6">
            <div>
              <h3 className="mb-4 text-[#000000]/70 font-medium">Services</h3>
              <ul className="space-y-2 text-sm text-[#0E0E0D]/70">
                {services.map((service, index) => (
                  <li key={index}>
                    <Link
                      to={service.path}
                      className="transition hover:opacity-75"
                    >
                      {service.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            {/* {!isMobile && ( */}
            <div>
              <h3 className="font-medium mb-4 text-[#000000]/70">About Us</h3>
              <ul className="space-y-2 text-sm text-[#0E0E0D]/70">
                {aboutUs.map((service, index) => (
                  <li key={index}>
                    <Link
                      to={service.path}
                      className="transition hover:opacity-75"
                    >
                      {service.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            {/* )} */}

            <div>
              <h3 className="font-medium mb-4 text-[#000000]/70">
                Other Links
              </h3>
              <ul className="space-y-2 text-sm text-[#0E0E0D]/70">
                {otherLinks.map((link, index) => (
                  <li key={index}>
                    <a href={link.path} className="transition hover:opacity-75">
                      {link.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div>
              <h3 className="font-medium mb-4 text-[#000000]/70">
                Contact Details
              </h3>
              <ul className="space-y-2 text-sm text-[#0E0E0D]/70">
                <a
                  href="https://maps.app.goo.gl/yBomZs6Zw619DKtF7"
                  target="_blank"
                  rel="noreferrer"
                >
                  <SlLocationPin className="mr-3 inline text-xl imac:text-5xl" />
                  Bafal, Sitapaila, Kathmandu
                </a>
                <br />
                <li>
                  <a
                    href={"tel:01-5233236"}
                    className="transition hover:opacity-75"
                  >
                    <BiPhone className="mr-3 inline text-xl imac:text-5xl" />
                    01-5233236
                  </a>
                  ,&ensp;
                  <a
                    href={"tel:01-5346557"}
                    className="transition hover:opacity-75"
                  >
                    01-5346557
                  </a>
                </li>
                <li>
                  <a
                    href={"tel:9851133941"}
                    className="transition hover:opacity-75"
                  >
                    <BiPhone className="mr-3 inline text-xl imac:text-5xl" />
                    9851133941
                  </a>
                  ,&ensp;
                  <a
                    href={"tel:9849480961"}
                    className="transition hover:opacity-75"
                  >
                    9849480961
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 justify-between items-center text-sm pt-6 space-y-4 md:space-y-0 text-[#000000]/70">
          <div className="text-center md:text-left">
            ©{year}. All Rights Reserved. Developed by{" "}
            <a
              href="https://octacore.com.np/"
              className="font-semibold hover:underline"
            >
              Octacore Solutions
            </a>
          </div>
          <div className="flex justify-center md:justify-end space-x-6">
            <a href="#!" className="transition underline hover:opacity-75">
              Privacy Policy
            </a>
            <a href="#!" className="transition underline hover:opacity-75">
              Terms Of Services
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
