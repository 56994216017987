import React, { useEffect, useRef } from "react";
import { scrambleText } from "./scrambleText.js";
import "../styles/style.css"


const messages = [
  { delay: 0, text: "ERROR 404 NOT FOUND", size: "text-6xl", weight: "font-bold" },
  { delay: 800, text: "You may have mis-typed the URL.", size: "text-2xl", weight: "font-light" },
  { delay: 1800, text: "Or the page has been removed.", size: "text-2xl", weight: "font-light" },
];

const Message = ({ text, delay, size, weight }) => {
  const ref = useRef();

  useEffect(() => {
    scrambleText(ref.current, text, { delay });
  }, [text, delay]);

  return <p ref={ref} className={`text-[#000000] ${size} ${weight} mb-4`}></p>;
};

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[#FEF6E5] font-poppins">
      <div className="notFound cursor-pointer">
        <a href="/" className="button_top">
        <div id="message">
          {messages.map((message, index) => (
            <Message key={index} {...message} />
          ))}
        </div>
          <div className="flex space-x-4 justify-center">
          <a
          className="text-black hover:-translate-y-1 active:translate-y-0 transition duration-400 underline"
          href="/"
        >
          BACK TO HOME
        </a>
          </div>
        </a>
        
        
      </div>
    </div>
  );
};

export default NotFound;
