export const scrambleText = (element, text, options) => {
    const defaults = {
      probability: 0.2,
      glitches: '-|/\\',
      blank: '',
      duration: text.length * 40,
      ease: 'easeInOutQuad',
      delay: 0,
    };
  
    const settings = { ...defaults, ...options };
    const glitchCharacters = settings.glitches.split('');
    const glitchLength = glitchCharacters.length;
    const ghostCharacters = element.textContent.split('');
    const ghostLength = ghostCharacters.length;
    const textCharacters = text.split('');
    const textLength = textCharacters.length;
    const order = [...Array(textLength).keys()].sort(() => (Math.random() > 0.5 ? 1 : -1));
    const output = [];
  
    for (let i = 0; i < textLength; i++) {
      const glitchIndex = Math.floor(Math.random() * glitchLength);
      const glitchCharacter = `<span class="glitch">${glitchCharacters[glitchIndex]}</span>`;
      const ghostCharacter = ghostCharacters[i] || settings.blank;
      const addGlitch = Math.random() < settings.probability;
      const character = addGlitch ? glitchCharacter : ghostCharacter;
      output.push(character);
    }
  
    const object = { value: 0 };
    const target = { value: 1 };
  
    const animate = () => {
      const progress = Math.floor(object.value * (textLength - 1));
      for (let i = 0; i <= progress; i++) {
        const index = order[i];
        output[index] = textCharacters[index];
      }
      element.innerHTML = output.join('');
      if (object.value < 1) {
        object.value += 0.01;
        requestAnimationFrame(animate);
      }
    };
  
    setTimeout(() => {
      animate();
    }, settings.delay);
  };
  