import React from "react";

const SampleCategorization = ({ category }) => {
  // const samples = [
  //   {tag: 'Books', primaryImg: 'book-1.jpg' },
  //   {tag: 'Books', primaryImg: 'sample-book-2.png' },
  //   {tag: 'Books', primaryImg: 'sample-book-3.png' },
  //   {tag: 'Catalogs', primaryImg: 'catalog-1.png' },
  //   {tag: 'Magazines & Storybook', primaryImg: 'sample-magazine-2.jpeg' },
  //   {tag: 'Posters', primaryImg: 'sample-poster-1.png' },
  //   {tag: 'Posters', primaryImg: 'sample-poster-2.png' },
  // ];

  const samples = [
    // Books & Reports
    { tag: "Books & Reports", primaryImg: "book-1.jpg" },
    { tag: "Books & Reports", primaryImg: "book-2.jpg" },
    { tag: "Books & Reports", primaryImg: "book-3.jpg" },
    { tag: "Books & Reports", primaryImg: "book-4.jpg" },
    { tag: "Books & Reports", primaryImg: "book-5.jpg" },
    { tag: "Books & Reports", primaryImg: "book-6.jpg" },
    { tag: "Books & Reports", primaryImg: "book-7.jpg" },
    { tag: "Books & Reports", primaryImg: "book-8.jpg" },
    { tag: "Books & Reports", primaryImg: "book-9.jpg" },
    { tag: "Books & Reports", primaryImg: "book-10.jpg" },
    { tag: "Books & Reports", primaryImg: "book-11.jpg" },
    { tag: "Books & Reports", primaryImg: "book-12.jpg" },
    { tag: "Books & Reports", primaryImg: "book-13.jpg" },
    { tag: "Books & Reports", primaryImg: "book-14.jpg" },
    { tag: "Books & Reports", primaryImg: "book-15.jpg" },
    { tag: "Books & Reports", primaryImg: "book-16.jpg" },
    { tag: "Books & Reports", primaryImg: "book-17.jpg" },
    { tag: "Books & Reports", primaryImg: "book-18.jpg" },
    { tag: "Books & Reports", primaryImg: "book-19.jpg" },
    { tag: "Books & Reports", primaryImg: "book-20.jpg" },
    { tag: "Books & Reports", primaryImg: "book-21.jpg" },
    { tag: "Books & Reports", primaryImg: "book-22.jpg" },
    { tag: "Books & Reports", primaryImg: "book-23.jpg" },
    { tag: "Books & Reports", primaryImg: "book-24.jpg" },
    { tag: "Books & Reports", primaryImg: "book-25.jpg" },
    { tag: "Books & Reports", primaryImg: "book-26.jpg" },
    { tag: "Books & Reports", primaryImg: "book-27.jpg" },
    { tag: "Books & Reports", primaryImg: "book-28.jpg" },
    { tag: "Books & Reports", primaryImg: "book-29.jpg" },
    { tag: "Books & Reports", primaryImg: "book-30.jpg" },
    { tag: "Books & Reports", primaryImg: "book-31.jpg" },
    { tag: "Books & Reports", primaryImg: "book-32.jpg" },

    // Catalogs
    { tag: "Catalogs", primaryImg: "catalog-1.jpg" },
    { tag: "Catalogs", primaryImg: "catalog-2.jpg" },
    { tag: "Catalogs", primaryImg: "catalog-3.jpg" },
    { tag: "Catalogs", primaryImg: "catalog-4.jpg" },
    { tag: "Catalogs", primaryImg: "catalog-5.jpg" },
    { tag: "Catalogs", primaryImg: "catalog-6.jpg" },
    { tag: "Catalogs", primaryImg: "catalog-7.jpg" },
    { tag: "Catalogs", primaryImg: "catalog-8.jpg" },
    { tag: "Catalogs", primaryImg: "catalog-9.jpg" },
    { tag: "Catalogs", primaryImg: "catalog-10.jpg" },
    { tag: "Catalogs", primaryImg: "catalog-11.jpg" },

    // Hardbound
    { tag: "Hardbound", primaryImg: "hardbound-1.jpg" },
    { tag: "Hardbound", primaryImg: "hardbound-2.jpg" },
    { tag: "Hardbound", primaryImg: "hardbound-3.jpg" },
    { tag: "Hardbound", primaryImg: "hardbound-4.jpg" },
    { tag: "Hardbound", primaryImg: "hardbound-5.jpg" },
    { tag: "Hardbound", primaryImg: "hardbound-6.jpg" },
    { tag: "Hardbound", primaryImg: "hardbound-7.jpg" },
    { tag: "Hardbound", primaryImg: "hardbound-8.jpg" },

    // Magazines & Storybook
    { tag: "Magazines & Storybook", primaryImg: "magazine-1.jpg" },
    { tag: "Magazines & Storybook", primaryImg: "magazine-2.jpg" },
    { tag: "Magazines & Storybook", primaryImg: "magazine-3.jpg" },
    { tag: "Magazines & Storybook", primaryImg: "magazine-4.jpg" },
    { tag: "Magazines & Storybook", primaryImg: "magazine-5.jpg" },
    { tag: "Magazines & Storybook", primaryImg: "magazine-6.jpg" },
    { tag: "Magazines & Storybook", primaryImg: "storybook-1.jpg" },
    { tag: "Magazines & Storybook", primaryImg: "storybook-2.jpg" },
    { tag: "Magazines & Storybook", primaryImg: "storybook-3.jpg" },

    // Boxes
    { tag: "Boxes", primaryImg: "Boxes/snplmangaldeep.jpg" },
    { tag: "Boxes", primaryImg: "Boxes/sunsilk.jpg" },
    { tag: "Boxes", primaryImg: "Boxes/toffichoo.jpg" },
    { tag: "Boxes", primaryImg: "Boxes/bennevis.jpg" },
    { tag: "Boxes", primaryImg: "Boxes/choco.jpg" },
    { tag: "Boxes", primaryImg: "Boxes/glow&lovely.jpg" },
    { tag: "Boxes", primaryImg: "Boxes/kasthamandap.jpg" },
    { tag: "Boxes", primaryImg: "Boxes/mangaldeep.jpg" },
    { tag: "Boxes", primaryImg: "Boxes/muesli.jpg" },
    { tag: "Boxes", primaryImg: "Boxes/muglan.jpg" },
    { tag: "Boxes", primaryImg: "Boxes/nb.jpg" },
    { tag: "Boxes", primaryImg: "Boxes/novabutter.jpg" },
    { tag: "Boxes", primaryImg: "Boxes/phoolprasad.jpg" },
    { tag: "Boxes", primaryImg: "Boxes/ponds.jpg" },

    // Posters
    { tag: "Posters", primaryImg: "Posters/jazzmandu.jpg" },
    { tag: "Posters", primaryImg: "Posters/mangaldeep.jpg" },
    { tag: "Posters", primaryImg: "Posters/mustang.jpg" },
    { tag: "Posters", primaryImg: "Posters/pepsi.jpg" },
    { tag: "Posters", primaryImg: "Posters/redbull.jpg" },
    { tag: "Posters", primaryImg: "Posters/signature.jpg" },
    { tag: "Posters", primaryImg: "Posters/stc.jpg" },
    { tag: "Posters", primaryImg: "Posters/sujal.jpg" },
    { tag: "Posters", primaryImg: "Posters/suryanepal.jpg" },
    { tag: "Posters", primaryImg: "Posters/tadka.jpg" },
    { tag: "Posters", primaryImg: "Posters/wheel.jpg" },
    { tag: "Posters", primaryImg: "Posters/zsl.jpg" },
    { tag: "Posters", primaryImg: "Posters/appy.jpg" },
    { tag: "Posters", primaryImg: "Posters/baltra.jpg" },
    { tag: "Posters", primaryImg: "Posters/bikalpa.jpg" },
    { tag: "Posters", primaryImg: "Posters/cocacola.jpg" },
    { tag: "Posters", primaryImg: "Posters/f&h.jpg" },
    { tag: "Posters", primaryImg: "Posters/frooti.jpg" },
    { tag: "Posters", primaryImg: "Posters/fusion.jpg" },
    { tag: "Posters", primaryImg: "Posters/gorkha.jpg" },
    { tag: "Posters", primaryImg: "Posters/happydent.jpg" },
    { tag: "Posters", primaryImg: "Posters/heifer.jpg" },
    { tag: "Posters", primaryImg: "Posters/himalayaolevera.jpg" },

    // Streamers
    { tag: "Streamers", primaryImg: "Streamers/sujal.jpg" },
    { tag: "Streamers", primaryImg: "Streamers/bajaj.jpg" },
    { tag: "Streamers", primaryImg: "Streamers/gorkha.jpg" },
    { tag: "Streamers", primaryImg: "Streamers/holi.jpg" },
    { tag: "Streamers", primaryImg: "Streamers/khukri.jpg" },
    { tag: "Streamers", primaryImg: "Streamers/ponds.jpg" },
    { tag: "Streamers", primaryImg: "Streamers/samsung.jpg" },
    { tag: "Streamers", primaryImg: "Streamers/soaltee.jpg" },
  ];

  // console.log({ category });

  return (
    <div className="max-w-screen-2xl mx-auto px-5 grid md:grid-cols-4 sm:grid-cols-2 gap-6 pb-10">
      {samples.map((sample, index) => {
        if (category === "All") {
          return (
            <div key={index} className="w-full ">
              <img
                src={require(`../../assets/images/${sample.primaryImg}`)}
                alt={sample.tag}
                className="object-cover rounded-2xl hover:scale-[1.2] duration-300 ease-in-out w-full"
              />
            </div>
          );
        } else if (category === sample.tag) {
          return (
            <div key={index} className="w-full">
              <img
                src={require(`../../assets/images/${sample.primaryImg}`)}
                alt={sample.tag}
                className="object-cover rounded-2xl hover:scale-[1.13] duration-300 ease-in-out w-full"
              />
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default SampleCategorization;
