import axios from "axios";
import React, { useState } from "react";
import { MdAttachFile, MdDeleteOutline } from "react-icons/md";
import { toast } from "react-toastify";
import { BASE_URL } from "../../util/axios/axios";

const GetAQuote = () => {
  //
  const [company, setCompany] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [email, setEmail] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [jobType, setJobType] = useState("");
  const [quantity, setQuantity] = useState("");
  const [size, setSize] = useState("");
  const [printingType, setPrintingType] = useState("");
  const [noOfPage, setNoOfPage] = useState("");
  const [coverPaper, setCoverPaper] = useState("");
  const [innerPaper, setInnerPaper] = useState("");
  const [otherPaper, setOtherPaper] = useState("");
  const [services, setServices] = useState([]);
  const [fileAttachment, setFileAttachment] = useState("");
  const [extraInformation, setExtraInformation] = useState("");

  //ID changed to match the correct service types
  const servicesType = [
    { service: "Binding", id: 1 },
    { service: "Lamination", id: 2 },
    { service: "Spot UV", id: 3 },
    { service: "Steamer", id: 4 },
    { service: "UV", id: 5 },
    { service: "Embossing", id: 6 },
    { service: "Die Cutting", id: 7 },
    { service: "Window Patching", id: 8 },
  ];
  const handleServiceChange = (service) => {
    setServices((prevServices) => {
      const updatedServices = prevServices.includes(service)
        ? prevServices.filter((s) => s !== service)
        : [...prevServices, service];
      console.log("Updated Services:", updatedServices);
      return updatedServices;
    });
  };
  // const handleServiceChange = (service) => {
  //   setServices((prevServices) =>
  //     prevServices.includes(service)
  //       ? prevServices.filter((s) => s !== service)
  //       : [...prevServices, service]
  //   );
  //   console.log(services);
  // };
  //   const handleServiceChange = (serviceId) => {
  //     setServices((prevData) => {
  //         if (prevData.includes(serviceId)) {
  //             return {
  //                 ...prevData,
  //                 services: prevData.filter((id) => id !== serviceId)
  //             };
  //         } else {
  //             return {
  //                 ...prevData,
  //                 services: [...prevData, serviceId]
  //             };
  //         }
  //     });
  // };
  // const submitQuote = (event) => {
  //   event.preventDefault();

  //   const payload = {
  //     company_name: company,
  //     email: email,
  //     job: jobTitle,
  //     contact_person: contactPerson,
  //     phone: contactNumber,
  //     quantity: quantity,
  //     cover_paper: coverPaper,
  //     inner_paper: innerPaper,
  //     other_paper: otherPaper,
  //     services: services,
  //     printing: printingType,
  //     paper_size: size,
  //     pages: noOfPage,
  //     document: fileAttachment,
  //     questions: extraInformation,
  //   };
  //   console.log(services);
  //   let config = {
  //     method: "post",
  //     maxBodyLength: Infinity,
  //     url: `${BASE_URL}getAQuote/`,
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //     },
  //     data: payload,
  //   };

  //   axios
  //     .request(config)
  //     .then((response) => {
  //       setCompany("");
  //       setJobTitle("");
  //       setEmail("");
  //       setContactPerson("");
  //       setContactNumber("");
  //       setJobType("");
  //       setQuantity("");
  //       setSize("");
  //       setPrintingType("");
  //       setNoOfPage("");
  //       setCoverPaper("");
  //       setInnerPaper("");
  //       setOtherPaper("");
  //       setServices([]);
  //       setFileAttachment("");
  //       setExtraInformation("");

  //       toast.success("Quote Request succesful!");
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       toast.error("Quote Request has failed!");
  //     });
  // };
  const submitQuote = (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("company_name", company);
    formData.append("email", email);
    formData.append("job", jobTitle);
    formData.append("contact_person", contactPerson);
    formData.append("phone", contactNumber);
    formData.append("quantity", quantity);
    formData.append("cover_paper", coverPaper);
    formData.append("inner_paper", innerPaper);
    formData.append("other_paper", otherPaper);
    formData.append("printing", printingType);
    formData.append("paper_size", size);
    formData.append("pages", noOfPage);
    formData.append("document", fileAttachment);
    formData.append("questions", extraInformation);

    // Append services as an array
    services.forEach((service) => {
      formData.append("services", service);
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${BASE_URL}getAQuote/`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    // Show loading toast
    const toastId = toast.loading("Requesting Quote...");

    axios
      .request(config)
      .then((response) => {
        setCompany("");
        setJobTitle("");
        setEmail("");
        setContactPerson("");
        setContactNumber("");
        setJobType("");
        setQuantity("");
        setSize("");
        setPrintingType("");
        setNoOfPage("");
        setCoverPaper("");
        setInnerPaper("");
        setOtherPaper("");
        setServices([]);
        setFileAttachment("");
        setExtraInformation("");

        // Update loading toast to success
        toast.update(toastId, {
          render: "Quote Request successful!",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      })
      .catch((error) => {
        console.log(error);

        // Update loading toast to error
        toast.update(toastId, {
          render: "Quote Request has failed.",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      });
  };
  return (
    <form onSubmit={submitQuote} action="" className="font-poppins mt-4">
      <h1 className="pb-8 font-semibold text-left text-xl">Company Details</h1>
      <div className="md:grid md:grid-cols-2 gap-8">
        <div className="relative z-0 w-full mb-5 md:mb-3 group">
          <input
            name="company"
            id="company"
            className="block py-2.5 px-4 text-[16px] w-full rounded-sm bg-[#1D306D]/5 focus:outline-none focus:ring-0 focus:type peer"
            placeholder=""
            value={company}
            onChange={(e) => {
              setCompany(e.target.value);
            }}
            required
          />
          <label
            htmlFor="company"
            className="peer-focus:font-medium absolute text-[16px] text-gray-500 duration-300 transform start-4 -translate-y-8 scale-85 top-3 -z-10 peer-focus:start-0 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
          >
            Company *
          </label>
        </div>
        <div className="relative z-0 w-full mb-5 md:mb-3 group">
          <input
            name="Email"
            id="Email"
            className="block py-2.5 px-4 text-[16px] w-full rounded-sm bg-[#1D306D]/5 focus:outline-none focus:ring-0 focus:type peer"
            placeholder=""
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            required
          />
          <label
            htmlFor="Email"
            className="peer-focus:font-medium absolute text-[16px] text-gray-500 duration-300 transform start-4 -translate-y-8 scale-85 top-3 -z-10 peer-focus:start-0 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
          >
            Email *
          </label>
        </div>
        <div className="relative z-0 w-full mb-5 md:mb-3 group">
          <input
            name="contactPerson"
            id="contactPerson"
            className="block py-2.5 px-4 text-[16px] w-full rounded-sm bg-[#1D306D]/5 focus:outline-none focus:ring-0 focus:type peer"
            placeholder=""
            value={contactPerson}
            onChange={(e) => {
              setContactPerson(e.target.value);
            }}
            required
          />
          <label
            htmlFor="contactPerson"
            className="peer-focus:font-medium absolute text-[16px] text-gray-500 duration-300 transform start-4 -translate-y-8 scale-85 top-3 -z-10 peer-focus:start-0 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
          >
            Contact Person *
          </label>
        </div>
        <div className="relative z-0 w-full mb-5 md:mb-3 group">
          <input
            type="number"
            name="contactNumber"
            id="contactNumber"
            min={0}
            className="block py-2.5 px-4 text-[16px] w-full rounded-sm bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer"
            placeholder=""
            value={contactNumber}
            onChange={(e) => {
              setContactNumber(e.target.value);
            }}
            required
          />
          <label
            htmlFor="contactNumber"
            className="peer-focus:font-medium absolute text-[16px] text-gray-500 duration-300 transform start-4 -translate-y-8 scale-85 top-3 -z-10 peer-focus:start-0 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
          >
            Contact Number *
          </label>
        </div>
      </div>
      <h1 className="py-8 font-semibold text-left text-xl">Quote Details</h1>

      <div className="md:grid md:grid-cols-2 gap-8">
        <div className="relative z-0 w-full mb-5 md:mb-3 group">
          <input
            name="jobTitle"
            id="jobTitle"
            className="block py-2.5 px-4 text-[16px] w-full rounded-sm bg-[#1D306D]/5 focus:outline-none focus:ring-0 focus:type peer"
            placeholder=""
            value={jobTitle}
            onChange={(e) => {
              setJobTitle(e.target.value);
            }}
            required
          />
          <label
            htmlFor="jobTitle"
            className="peer-focus:font-medium absolute text-[16px] text-gray-500 duration-300 transform start-4 -translate-y-8 scale-85 top-3 -z-10 peer-focus:start-0 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
          >
            Job Title *
          </label>
        </div>
        <div className="relative z-0 w-full mb-5 md:mb-3 group">
          <input
            name="jobType"
            id="jobType"
            className="block py-2.5 px-4 text-[16px] w-full rounded-sm bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer "
            placeholder=""
            value={jobType}
            onChange={(e) => {
              setJobType(e.target.value);
            }}
          />
          <label
            htmlFor="jobType"
            className="peer-focus:font-medium absolute text-[16px] text-gray-500 duration-300 transform start-4 -translate-y-8 scale-85 top-3 -z-10 peer-focus:start-0 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
          >
            Job Type
          </label>
        </div>
        <div className="relative z-0 w-full mb-5 md:mb-3 group">
          <input
            type="number"
            name="quantity"
            id="quantity"
            className="block py-2.5 px-4 text-[16px] w-full rounded-sm bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer "
            placeholder=""
            min={0}
            value={quantity}
            onChange={(e) => {
              setQuantity(e.target.value);
            }}
            required
          />
          <label
            htmlFor="quantity"
            className="peer-focus:font-medium absolute text-[16px] text-gray-500 duration-300 transform start-4 -translate-y-8 scale-85 top-3 -z-10 peer-focus:start-0 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
          >
            Quantity *
          </label>
        </div>
        <div className="relative z-0 w-full mb-5 md:mb-3 group">
          <input
            type="number"
            name="noOfPage"
            id="noOfPage"
            min={0}
            className="block py-2.5 px-4 text-[16px] w-full rounded-sm bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer "
            placeholder=""
            value={noOfPage}
            onChange={(e) => {
              setNoOfPage(e.target.value);
            }}
          />
          <label
            htmlFor="noOfPage"
            className="peer-focus:font-medium absolute text-[16px] text-gray-500 duration-300 transform start-4 -translate-y-8 scale-85 top-3 -z-10 peer-focus:start-0 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
          >
            No of Page
          </label>
        </div>
        <div className="relative z-0 w-full mb-5 md:mb-3 group">
          <input
            x
            name="size"
            id="size"
            className="block py-2.5 px-4 text-[16px] w-full rounded-sm bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer "
            placeholder=""
            value={size}
            onChange={(e) => {
              setSize(e.target.value);
            }}
          />
          {/* <select
            name="size"
            id="size"
            className="block py-2.5 px-4 text-[16px] w-full rounded-sm bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer "
            placeholder=""
            value={size}
            onChange={(e) => {
              setSize(e.target.value);
            }}
            required
          >
            <option value="" disabled selected>
              Choose size
            </option>
            <option value="a4">A4</option>
            <option value="a3">A3</option>
            <option value="a2">A2</option>
            <option value="a5">A5</option>
          </select> */}
          <label
            htmlFor="size"
            className="peer-focus:font-medium absolute text-[16px] text-gray-500 duration-300 transform start-4 -translate-y-8 scale-85 top-3 -z-10 peer-focus:start-0 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
          >
            Size *
          </label>
        </div>
        <div className="relative z-0 w-full mb-5 md:mb-3 group">
          <select
            name="printingType"
            id="printingType"
            className="block py-2.5 px-4 text-[16px] w-full rounded-sm bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer "
            placeholder=""
            value={printingType}
            onChange={(e) => {
              setPrintingType(e.target.value);
            }}
            required
          >
            <option value="" disabled selected>
              {" "}
              Choose Type
            </option>
            <option value="single">Single Side</option>
            <option value="double">Both Side</option>
          </select>
          <label
            htmlFor="printingType"
            className="peer-focus:font-medium absolute text-[16px] text-gray-500 duration-300 transform start-4 -translate-y-8 scale-85 top-3 -z-10 peer-focus:start-0 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
          >
            Printing Type *
          </label>
        </div>
        <div className="relative z-0 w-full mb-5 md:mb-3 group">
          <input
            name="coverPaper"
            id="coverPaper"
            className="block py-2.5 px-4 text-[16px] w-full rounded-sm bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer "
            placeholder=""
            value={coverPaper}
            onChange={(e) => {
              setCoverPaper(e.target.value);
            }}
          />
          <label
            htmlFor="coverPaper"
            className="peer-focus:font-medium absolute text-[16px] text-gray-500 duration-300 transform start-4 -translate-y-8 scale-85 top-3 -z-10 peer-focus:start-0 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
          >
            Cover Paper *
          </label>
        </div>
        <div className="relative z-0 w-full mb-5 md:mb-3 group">
          <input
            name="innerPaper"
            id="innerPaper"
            className="block py-2.5 px-4 text-[16px] w-full rounded-sm bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer "
            placeholder=""
            value={innerPaper}
            onChange={(e) => {
              setInnerPaper(e.target.value);
            }}
          />
          <label
            htmlFor="innerPaper"
            className="peer-focus:font-medium absolute text-[16px] text-gray-500 duration-300 transform start-4 -translate-y-8 scale-85 top-3 -z-10 peer-focus:start-0 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
          >
            Inner Paper *
          </label>
        </div>
        <div className="relative z-0 w-full mb-5 md:mb-3 group">
          <input
            name="otherPaper"
            id="otherPaper"
            className="block py-2.5 px-4 text-[16px] w-full rounded-sm bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer "
            placeholder=""
            value={otherPaper}
            onChange={(e) => {
              setOtherPaper(e.target.value);
            }}
          />
          <label
            htmlFor="otherPaper"
            className="peer-focus:font-medium absolute text-[16px] text-gray-500 duration-300 transform start-4 -translate-y-8 scale-85 top-3 -z-10 peer-focus:start-0 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
          >
            Other Paper *
          </label>
        </div>
      </div>
      <div className="relative z-0 w-full mb-5 md:mb-3 group">
        <label className="peer-focus:font-medium text-[16px] text-gray-500 duration-300 transform text-left block">
          Services
        </label>
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 mt-2">
          {servicesType.map((service, index) => (
            <label
              htmlFor={service.service}
              className="flex gap-2 py-1"
              key={service.id}
              onChange={() => handleServiceChange(service.id)}
            >
              <input
                type="checkbox"
                name={service.service}
                id={service.service}
                value={service.id}
                className="accent-primary/90"
              />
              {service.service}
            </label>
          ))}
        </div>
      </div>
      <div className="relative z-0 w-full mb-5 md:mb-3 group">
        <label className="peer-focus:font-medium text-[16px] text-gray-500 duration-300 transform text-left block">
          File Attachment
        </label>
        <div className="grid lg:grid-cols-[70%_30%] w-full">
          <div className="flex justify-between items-center w-full border rounded-sm p-2 bg-[#1D306D]/5">
            <div className="flex gap-2 text-[16px] text-gray-600 cursor-pointer items-center">
              <MdAttachFile className="text-lg" />
              {fileAttachment ? (
                <span>{fileAttachment}</span>
              ) : (
                <span>Attach your file here</span>
              )}
              <input
                type="file"
                name="fileAttachment"
                id="fileAttachment"
                className="hidden"
                onChange={(e) => {
                  if (e.target.files.length > 0) {
                    setFileAttachment(e.target.files[0].name);
                  }
                }}
              />
            </div>
            {fileAttachment && (
              <MdDeleteOutline
                className="text-red-400 text-lg cursor-pointer"
                onClick={() => {
                  setFileAttachment("");
                  document.getElementById("fileAttachment").value = null;
                }}
              />
            )}
          </div>
          <div className="lg:w-[95%] lg:ml-[5%] lg:mt-0 mt-5">
            <button
              className="linkage-secondary cursor-pointer w-full"
              onClick={() => document.getElementById("fileAttachment").click()}
            >
              <span className="button_top w-full">Choose File</span>
            </button>
          </div>
        </div>
      </div>

      <div className="relative z-0 w-full mb-5 md:mb-3 group">
        <textarea
          name="extraInformation"
          id="extraInformation"
          className="block py-2.5 px-4 text-[16px] w-full h-40 rounded-sm bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer"
          placeholder=""
          value={extraInformation}
          onChange={(e) => {
            setExtraInformation(e.target.value);
          }}
        ></textarea>
        <label
          htmlFor="extraInformation"
          className="peer-focus:font-medium absolute text-[16px] text-gray-500 duration-300 transform start-4 -translate-y-8 scale-85 top-3 -z-10 peer-focus:start-0 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
        >
          Extra Information
        </label>
      </div>
      <button type="submit" className="linkage-primary cursor-pointer">
        <span className="button_top">Submit</span>
      </button>
    </form>
  );
};

export default GetAQuote;
