import React from 'react'
import ServiceLanding from '../components/services/serviceLanding'
import ServiceProcess from '../components/services/serviceProcess'
import ServiceSamples from '../components/services/serviceSamples'
import "../styles/style.css"

const services = () => {
  return (
    <div className='bg-[#FEF8F0] relative font-poppins '>
      <ServiceLanding />
      <ServiceProcess />
      <ServiceSamples />
    </div>
  )
}

export default services
