import React from "react";
import "../styles/slides.css";

const Clients = () => {
  // let banks = [
  //   "EBL_LOGO.png",
  //   "Himalayan everest insurance.png",
  //   "Kumari-Bank-Limited.jpg",
  //   "nchl.jpeg",
  //   "prabhumahalaxmi.png",
  //   "siddhartha-premier-insurance.jpeg",
  //   "siddhartha.png",
  // ];

  // let ngos = [
  //   "equalaccess.png",
  //   "ICRC logo card.png",
  //   "ifes.png",
  //   "peeda.png",
  //   "unfpa-logo.png",
  //   "VOW-Media.png",
  //   "undp.webp",
  //   "pin.webp",
  //   "unesco.png",
  //   "icimod.png",
  //   "save the children.png",
  //   "UNICEF-latest-logo.png",
  //   "who.png",
  //   "worldvision.png",
  // ];

  // let corporates = [
  //   "agni.png",
  //   "asian paints.png",
  //   "JLECC_logo_0812.png",
  //   "ce.png",
  //   "dabur.png",
  //   "emai.png",
  //   "Golchha-Group-100-Years-of-Trust.png",
  //   "KU.png",
  //   "HMH.png",
  //   "KWS.png",
  //   "MAW.png",
  //   "nba.png",
  //   "phoolprasad.png",
  //   "prismark.png",
  //   "pycus_holdings_pvt_ltd_logo.png",
  //   "rb diamonds.png",
  //   "roomtoreadlogocolor.png",
  //   "sab.png",
  //   "siddhipoly.png",
  //   "surya nepal.png",
  //   "Unilever.svg.png",
  //   "urban foods.png",
  //   "vb.png",
  //   "DLF greens.png",
  //   "Heifer-Nepal-LogoH_CMYK.png",
  //   "Herveda-Botanicals-Logo2.webp",
  //   "hotelmulberry.png",
  //   "Kansai_Nerolac_Paints_logo.svg.png",
  //   "kasthamandap.png",
  //   "kedia-main-logo.png",
  //   "Klab.png",
  //   "nepalliquors.png",
  //   "Outreach.png",
  //   "UND.png",
  //   "Perfetti_Van_Melle_logo.svg",
  //   "skylight.png",
  //   "sujal dairy.png",
  //   "sujal-foods-official-logo.png",
  //   "piuro.png",
  //   "helvetas.png",
  //   "gorkha brewery.png",
  //   "Better.png",
  //   "Daraz-Logo.png",
  //   "hilife.png",
  //   "himelectronics.png",
  //   "KVC.png",
  //   "vchitra.png",
  //   "cg.png",
  //   "Samsung_Brand_in_Nepal.png",
  //   "GWTN.png",
  //   "thenepaldistilleries.jpeg",
  //   "shivam_logo_9.png",
  //   "shree vairab.jpeg",
  //   "baltra.png",
  //   "bottlersnepal.png",
  // ];
  // // let corporates = [
  // //   "agni.png",
  // //   "asian paints.png",
  // //   "Better.png",
  // //   "baltra.png",
  // //   "bottlersnepal.png",
  // //   "ce.png",
  // //   "cg.png",
  // //   "dabur.png",
  // //   "Daraz-Logo.png",
  // //   "DLF greens.png",
  // //   "emai.png",
  // //   "Golchha-Group-100-Years-of-Trust.png",
  // //   "gorkha brewery.png",
  // //   "GWTN.png",
  // //   "Heifer-Nepal-LogoH_CMYK.png",
  // //   "helvetas.png",
  // //   "Herveda-Botanicals-Logo2.webp",
  // //   "hilife.png",
  // //   "himelectronics.png",
  // //   "HMH.png",
  // //   "hotelmulberry.png",
  // //   "JLECC_logo_0812.png",
  // //   "Kansai_Nerolac_Paints_logo.svg.png",
  // //   "kasthamandap.png",
  // //   "kedia-main-logo.png",
  // //   "Klab.png",
  // //   "KU.png",
  // //   "KVC.png",
  // //   "KWS.png",
  // //   "MAW.png",
  // //   "nba.png",
  // //   "nepalliquors.png",
  // //   "Outreach.png",
  // //   "Perfetti_Van_Melle_logo.svg",
  // //   "phoolprasad.png",
  // //   "piuro.png",
  // //   "prismark.png",
  // //   "pycus_holdings_pvt_ltd_logo.png",
  // //   "rb diamonds.png",
  // //   "roomtoreadlogocolor.png",
  // //   "sab.png",
  // //   "Samsung_Brand_in_Nepal.png",
  // //   "shivam_logo_9.png",
  // //   "shree vairab.jpeg",
  // //   "siddhipoly.png",
  // //   "skylight.png",
  // //   "sujal dairy.png",
  // //   "sujal-foods-official-logo.png",
  // //   "surya nepal.png",
  // //   "thenepaldistilleries.jpeg",
  // //   "UND.png",
  // //   "Unilever.svg.png",
  // //   "urban foods.png",
  // //   "vb.png",
  // //   "vchitra.png",
  // // ];

  // let ads = [
  //   "echo.png",
  //   "kritya hub.png",
  //   "Telejuprakashan.png",
  //   "nepal traveller.png",
  //   "pearl pub.png",
  //   "max_media_pvt_ltd__logo.png",
  // ];

  let clients = [
    "1unicef.jpg",
    "2who.jpg",
    "3icimod.jpg",
    "4undp.jpg",
    "5STC.jpg",
    "6heifer.jpg",
    "7helvetas.jpg",
    "8rtr.jpg",
    "9unfpa.jpg",
    "10equalaccess.jpg",
    "11gwtn.jpg",
    "12icrc.jpg",
    "13ifes.jpg",
    "14peeda.jpg",
    "15peopleinneed.jpg",
    "16unesco.jpg",
    "17vowmedia.jpg",
    "18worldvisioin.jpg",
    "19suryanepal.jpg",
    "20kedia.jpg",
    "21cg.jpg",
    "22bottlers nepal.jpg",
    "23gorkhabrewery.jpg",
    "24unilever.jpg",
    "25sujaldairy.jpg",
    "26sujalfoods.jpg",
    "27agnigroup.jpg",
    "28nepaldistilleries.jpg",
    "29sab.jpg",
    "30asianpaints.jpg",
    "31dabur.jpg",
    "32dlf.jpg",
    "33emami.jpg",
    "34golchha.jpg",
    "35maw.jpg",
    "36hilife.jpg",
    "37himelectronics.jpg",
    "38piuro.jpg",
    "39shivam.jpg",
    "40skylight.jpg",
    "41und.jpg",
    "42varun.jpg",
    "43samsung.jpg",
    "44baltra.jpg",
    "45ce.jpg",
    "46daraz.jpg",
    "47better.jpg",
    "48kansainerolac.jpg",
    "49rbdiamond.jpg",
    "50shreevairab.jpg",
    "51herveda.jpg",
    "52nepalliquors.jpg",
    "53kasthamandap.jpg",
    "54urban.jpg",
    "55perfetti.jpg",
    "56phoolprasad.jpg",
    "57ebl.jpg",
    "58kumaribank.jpg",
    "59siddhartha.jpg",
    "60himalayaneverstinsureance.jpg",
    "61prabhumahalaxmi.jpg",
    "62siddharthapremier.jpg",
    "63nchl.jpg",
    "64jlecc.jpg",
    "65ku.jpg",
    "66kvc.jpg",
    "67kws.jpg",
    "68nba.jpg",
    "69nepaltraveller.jpg",
    "69taleju.jpg",
    "70pearlpublication.jpg",
    "71echoad.jpg",
    "72maxmedia.jpg",
    "73outreach.jpg",
    "74prismark.jpg",
    "75vchitra.jpg",
    "76pycus.jpg",
    "77siddhipoly.jpg",
    "78klab.jpg",
    "79HMH.jpg",
    "80hotelmulberry.jpg",
  ];

  return (
    <div className="bg-[#FEF8F0] font-poppins min-h-[60vh]">
      <div className="max-w-screen-2xl mx-auto md:px-10 px-5 md:py-20 pt-20">
        <h1 className="font-semibold text-[32px] md:text-[48px] text-center sm:text-left pb-10">
          Our Valuable Clients
        </h1>

        <div className="flex flex-wrap gap-x-10 gap-y-8 pb-10 justify-center">
          {clients.map((client, index) => (
            <img
              className="object-contain md:max-h-40 max-h-28 md:max-w-40 max-w-28 sm:grayscale sm:opacity-60 hover:grayscale-0 hover:opacity-100 transition-grayscale transition-opacity duration-150 ease-in-out"
              key={index}
              src={require(`../assets/images/Company logo/resized logo/${client}`)}
              alt="bank-logo"
            />
          ))}
        </div>
        {/* <h2 className="font-semibold text-[24px] md:text-[32px] text-center sm:text-left pb-10">
          Banks
        </h2>

        <div className="flex flex-wrap gap-x-10 gap-y-8 pb-10">
          {banks.map((bank, index) => (
            <img
              className="object-contain md:max-h-14 max-h-10 sm:grayscale sm:opacity-60 hover:grayscale-0 hover:opacity-100 transition-grayscale transition-opacity duration-150 ease-in-out"
              key={index}
              src={require(`../assets/images/Company logo/bank/${bank}`)}
              alt="bank-logo"
            />
          ))}
        </div>

        <h2 className="font-semibold text-[24px] md:text-[32px] text-center sm:text-left py-10 border-t border-black">
          NGO's and INGO's
        </h2>

        <div className="flex flex-wrap gap-x-10 gap-y-8 pb-10">
          {ngos.map((ngo, index) => (
            <img
              className="object-contain md:max-h-14 max-h-10 sm:grayscale sm:opacity-60 hover:grayscale-0 hover:opacity-100 transition-grayscale transition-opacity duration-150 ease-in-out"
              key={index}
              src={require(`../assets/images/Company logo/ngo/${ngo}`)}
              alt="ngo-logo"
            />
          ))}
        </div>
        <h2 className="font-semibold text-[24px] md:text-[32px] text-center sm:text-left py-10 border-t border-black">
          Corporates
        </h2>

        <div className="flex flex-wrap gap-x-10 gap-y-8 pb-10">
          {corporates.map((corporate, index) => (
            <img
              className="object-contain md:max-h-14 max-h-10 sm:grayscale sm:opacity-60 hover:grayscale-0 hover:opacity-100 transition-grayscale transition-opacity duration-150 ease-in-out"
              key={index}
              src={require(`../assets/images/Company logo/corporates/${corporate}`)}
              alt="corporate-logo"
            />
          ))}
        </div>

        <h2 className="font-semibold text-[24px] md:text-[32px] text-center sm:text-left py-10 border-t border-black">
          Ad Agencies
        </h2>

        <div className="flex flex-wrap gap-x-10 gap-y-8 pb-10">
          {ads.map((ad, index) => (
            <img
              className="object-contain md:max-h-14 max-h-10 sm:grayscale sm:opacity-60 hover:grayscale-0 hover:opacity-100 transition-grayscale transition-opacity duration-150 ease-in-out"
              key={index}
              src={require(`../assets/images/Company logo/adagencies/${ad}`)}
              alt="ad-logo"
            />
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default Clients;
