import React, { useState } from "react";
import SampleCategorization from "../components/samples/sampleCatgeorization";

const Samples = () => {
  const [category, setCategory] = useState("Books & Reports");

  const categories = [
    "Books & Reports",
    "Catalogs",
    "Hardbound",
    "Magazines & Storybook",
    "Boxes",
    "Posters",
    "Streamers",
  ];

  return (
    <div className="relative font-poppins min-h-[83vh] pt-32 bg-[#FEF8F0] -z-[-12] ">
      <div className="background">
        <div className="absolute left-[-30vw] top-[0vh] h-[110vh] w-[60vw] bg-cover rounded-full overflow-hidden border-2 border-[#FFCD12] bg-[#FEF8F0] -z-10">
          <div className="absolute w-full "></div>
        </div>
        <hr className="absolute top-[33vh] w-full border-[1.5px] border-[#FFCD12] overflow-hidden -z-[11]" />
      </div>

      <h1 className="max-w-screen-2xl mx-auto px-5 font-semibold text-[48px] lg:text-[80px] z-20 ">
        Our Samples
      </h1>

      <div className="max-w-screen-2xl mx-auto px-5 text-[20px] mt-16 xl:flex grid lg:grid-cols-4 md:grid-cols-2 z-20 xl:gap-5">
        {categories.map((cat, index) => (
          <div
            key={index}
            className={`pb-5 text-left cursor-pointer transition-colors duration-300 ${
              category === cat
                ? " font-semibold text-[22px] px-2 rounded-md"
                : "hover:underline  px-2 rounded-md"
            }`}
            onClick={() => setCategory(cat)}
          >
            {cat}
          </div>
        ))}
      </div>

      <div className=" mt-5  w-full ">
        <SampleCategorization category={category} />
      </div>
    </div>
  );
};

export default Samples;
