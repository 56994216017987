import React from "react";

const AboutUsLanding = () => {
  return (
    <div className="md:h-screen w-full grid md:grid-cols-[40%_60%]">
      <div className="md:max-h-screen max-h-[30vh] w-full">
        <img
          src={require("../../assets/images/sewaBuilding.jpeg")}
          className="object-cover w-full md:h-screen h-full"
          alt="About us Landing"
        />
      </div>
      <div className="md:my-auto w-full max-w-screen-2xl mx-auto py-10 px-4 md:px-10 space-y-6">
        <div className="flex items-center justify-start">
          <div className="bg-[#FFCD12] w-2 h-[3vh] mr-2"></div>
          <span className="font-semibold md:text-[20px] ">Who we are?</span>
        </div>
        <h1 className="font-bold text-[32px] md:text-[48px] leading-tight">
          About <span className="text-[#FFCD12]">Us</span>
        </h1>
        <p className=" md:text-xl mt-4 lg:mt-6 leading-relaxed text-justify">
          Established in 1988, Sewa Printing Press is promoted by a group of
          highly committed, innovative and well experienced persons and managed
          by a team of young and energetic professionals. Built on four decades
          of extensive experience in the printing industry, Sewa has been
          successfully catering to the multifarous printing needs of clientele
          that include several of the foremost national and international
          agencies, projects, corporate houses, government organizations &
          non-government organizations (NGOs) and advertising agencies among
          others.
        </p>
      </div>
    </div>
  );
};

export default AboutUsLanding;
