import React from "react";

const ServiceSamples = () => {
  return (
    <div className="max-w-screen-2xl mx-auto px-4 sm:px-6 lg:px-8 py-16 w-full h-auto relative z-0">
      <h1 className="font-semibold text-[32px] md:text-[48px] text-center sm:text-left">
        Our Samples
      </h1>
      <div className="flex flex-col sm:flex-row justify-between items-center mt-4 mb-8">
        <span className="text-[16px] sm:text-[20px] font-light md:text-justify w-full sm:w-[70%]">
          Browse through some our works to find inspiration for your next
          project and experience firsthand the exceptional results we deliver.
        </span>
        <a
          href="./services/samples"
          className="mt-4 sm:mt-0 linkage-secondary cursor-pointer"
        >
          <span className="button_top">View More</span>
        </a>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        <a
          href="./services/samples"
          className="relative h-[35vh] sm:h-[45vh] rounded-xl hover:scale-[1.03] duration-300"
        >
          <img
            src={require("../../assets/images/book-30.jpg")}
            alt="sample-bg"
            className="object-cover w-full h-full rounded-xl"
          />
          <span className="w-full absolute bottom-0 text-[#ffffff] py-3 flex justify-center items-center bg-[#383737]/50 rounded-b-xl">
            Books & Reports
          </span>
        </a>

        <a
          href="./services/samples"
          className="relative h-[35vh] sm:h-[45vh] rounded-xl hover:scale-[1.03] duration-300 cursor-pointer"
        >
          <img
            src={require("../../assets/images/hardbound-7.jpg")}
            alt="sample-bg"
            className="object-cover w-full h-full rounded-xl"
          />
          <span className="w-full absolute bottom-0 text-[#ffffff] py-3 flex justify-center items-center bg-[#383737]/50 rounded-b-xl">
            Hardbound
          </span>
        </a>
        <a
          href="./services/samples"
          className="relative h-[35vh] sm:h-[45vh] rounded-xl hover:scale-[1.03] duration-300 cursor-pointer"
        >
          <img
            src={require("../../assets/images/catalog-8.jpg")}
            alt="sample-bg"
            className="object-cover w-full h-full rounded-xl"
          />
          <span className="w-full absolute bottom-0 text-[#ffffff] py-3 flex justify-center items-center bg-[#383737]/50 rounded-b-xl cursor-pointer">
            Catalogs
          </span>
        </a>
      </div>
    </div>
  );
};

export default ServiceSamples;
