import React from "react";
// import Slider from "react-slick";

const AboutUsTeam = () => {
  // const settings = {
  //   infinite: true,
  //   dots: true,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   speed: 2000,
  //   autoplaySpeed: 5000,
  //   pauseOnHover: true,
  //   arrows: false,
  //   customPaging: function (i) {
  //     return <div className="dot w-[24px] h-[6px] bg-[#FFCD12]"></div>;
  //   },
  //   appendDots: (dots) => (
  //     <div>
  //       <ul className="flex justify-center space-x-4">{dots}</ul>
  //     </div>
  //   ),
  // };
  return (
    <div className="md:min-h-screen w-full border-y-2 border-b-[#FFCD12] flex items-center">
      <div className="max-w-screen-2xl mx-auto py-10 px-4 md:px-10 text-center">
        <h1 className="font-bold text-[32px] md:text-[48px] leading-tight">
          The Sewa Team
        </h1>
        <p className="md:text-xl mt-4 lg:mt-6 leading-relaxed text-justify mx-auto max-w-5xl">
          The hardworking team of Sewa Printing Press is the best because they
          deliver timely and accurate communication, ensuring information
          reaches the right audience effectively.
        </p>
        <div className="py-8 md:h-[70vh] h-full w-full">
          <img
            src={require("../../assets/images/sewa team.jpg")}
            alt="Sewa team"
            className="object-cover h-full w-full rounded-2xl"
          />
          {/* Image Slider Section */}
          {/* <Slider
            className="flex justify-center items-center about-gpa rounded-2xl"
            {...settings}
          >
            <img
              src={require("../../assets/images/sewa team.jpg")}
              alt="Sewa team"
              className="object-contain w-full rounded-xl"
            />
            <img
              src={require("../../assets/images/sewa team.png")}
              alt="Sewa team"
              className="object-contain w-full rounded-xl"
            />
          </Slider> */}
        </div>
      </div>
    </div>
  );
};

export default AboutUsTeam;
